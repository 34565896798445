/*----------------------------------------*/
/*  21. login Register
/*----------------------------------------*/
.login{
    &-form{
        background-color: $white;
        padding: 30px;
        -webkit-box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.1);
        box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.1);
        .check-box{
            display: flex;
            align-items: center;
        }
        label{
            display: block;
            font-size: 14px;
            margin-bottom: 12px;
            font-weight: 500;
            text-transform: capitalize;
        }
        input{
            width: 100%;
            background-color: transparent;
            border: 1px solid $border-color;
            border-radius: 0;
            line-height: 23px;
            padding: 10px 20px;
            font-size: 14px;
            margin-bottom: 15px;
        }
        input[type="checkbox"] {
            width: auto;
        }
    }
	&-title{
		font-size: 20px;
		line-height: 23px;
		text-transform: capitalize;
		font-weight: 700;
		margin-bottom: 0;
		padding-bottom: 20px;
	}
}

/*----------------------------------------*/
/*  18. Sidebar
/*----------------------------------------*/
.sidebar{
    &-widget{
        ul{
            text-align: center;
            li{
                transition: $baseTransition;
                a{
                    padding: 20px 0;
                    display: block;
                }
            }
        }
    }
    &-categories{
        background-color: $secondary;
        .title{
            border-bottom: 1px solid #5b749a;
            background-color: $primary;
            font-family: $rajdhani;
            font-weight: 600;
            font-size: 28px;
            padding-top: 10px;
            padding-bottom: 15px;
            text-align: center;
        }
        ul{
            li{
                &:not(:last-child){
                    a{
                        border-bottom: 1px solid #5b749a;
                    }
                }
                &:hover{
                    a{
                        background-color: $primary;
                        color: $white;
                    }
                    span{
                        color: $white;
                    }
                }
                a{
                    span{
                        transition: $baseTransition;
                        color: $primary;
                    }
                }
            }
        }
    }
    &-download-brochure{
        background-color: $primary;
        ul{
            li{
                &:not(:last-child){
                    a{
                        border-bottom: 1px solid #ffdac8;
                    }
                }
                &:hover{
                    a{
                        background-color: $secondary;
                        color: $white;
                    }
                }
            }
        }
    }
    &-banner{
        position: relative;
        .inner-content{
            position: absolute;
            top: 0;
            height: 100%;
            padding: 30px;
            &.style-1{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            &.style-2{
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                span{
                    font-size: 38px;
                    display: block;
                    margin-bottom: 10px;
                }
                .title{
                    text-shadow: 2px 2px 2px rgba(255,255,255,0.5);
                    font-size: 56px;
                }
            }
            .title{
                font-size: 32px;
                line-height: 30px;
            }
            .offer{
                border-left: 2px solid $secondary;
                padding-left: 15px;
            }
        }
    }
    &-title{
        position: relative;
        padding-bottom: 5px;
        display: inline-block;
        font-weight: 600;
        &:before{
            background-color: $secondary;
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 3px;
            left: 0;
        }
        &:after{
            background-color: $primary;
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 3px;
        }
    }
    &-common{
        padding: 30px 30px 35px;
    }
    &-form{
        position: relative;
        .searchbox{
            &-input{
                border: 1px solid $border-color;
                border-top-left-radius: 30px;
                border-top-right-radius: 30px;
                border-bottom-left-radius: 30px;
                border-bottom-right-radius: 30px;
                padding-left: 20px;
                padding-right: 60px;
                height: 45px;
                width: 100%;
            }
            &-btn{
                border-radius: 100%;
                position: absolute;
                top: 50%;
                right: 10px;
                padding: 0;
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                transform: translateY(-50%);
            }
        }
    }
    &-blog-categories{
        ul{
            li{
                a{
                    padding-top: 10px;
                    padding-bottom: 5px;
                    display: flex;
                    justify-content: space-between;
                }
                &:first-child{
                    a{
                        padding-top: 0;
                    }
                }
                &:last-child{
                    a{
                        padding-bottom: 0;
                    }
                }
                &:not(:last-child){
                    a{
                        border-bottom: 1px solid $border-color;
                    }
                }
            }
        }
    }
    &-list-wrap{
        .list{
            &-item{
                display: flex;
                &[class*="with-border"]{
                    &:not(:last-child){
                        border-bottom: 1px solid $border-color;
                        padding-bottom: 15px;
                        margin-bottom: 15px;
                    }
                }
            }
            &-img{
                width: 70px;
            }
            &-content{
                width: calc(100% - 70px);
                padding-left: 20px;
                margin-top: -5px;
                .title{
                    font-size: 18px;
                    line-height: 22px;
                }
            }
            &-meta{
                color: $primary;
            }
        }
    }
    &-tags{
        ul{
            display: flex;
            flex-wrap: wrap;
            margin: -2.5px;
            li{
                a{
                    border: 1px solid $border-color;
                    border-radius: 4px;
                    padding: 5px 10px;
                    font-size: 16px;
                    display: block;
                    margin: 2.5px;
                    &:hover{
                        background-color: $primary;
                        color: $white;
                    }
                }
            }
        }
    }
}
/*----------------------------------------*/
/*  10. Service
/*----------------------------------------*/
.service{
    &-img{
        display: block;
        img{
            width: 100%;
        }
        .add-action{
            background-color: $secondary;
            padding: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title{
                font-size: 28px;
                color: $white;
            }
            .icon{
                background-color: $primary;
                width: 35px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                i{
                    color: $white;
                    display: block;
                }
            }
        }
    }
}

/* ---Single Service--- */
.single{
    &-service{
        &-content{
            .sub-title{
                font-weight: 500;
                color: $primary;
                font-size: 28px;
            }
        }
        .service-list-area{
            .list-item{
                li{
                    display: flex;
                    font-size: 18px;
                    max-width: 360px;
                    &:not(:last-child){
                        padding-bottom: 20px;
                    }
                    .list-icon{
                        i{
                            vertical-align: middle;
                            color: $primary;
                        }
                    }
                    .list-text{
                        padding-left: 20px;
                    }
                }
                &.style{
                    &-2{
                        li{
                            display: inline-block;
                            span{
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
            .list-desc{
                max-width: 335px;
            }
        }
    }
}
/*----------------------------------------*/
/*  05. Offcanvas
/*----------------------------------------*/
.mobile-menu_wrapper,
.offcanvas-minicart_wrapper {
    .offcanvas-body {
        background-color: #333333;
        position: fixed;
        top: 0;
        right: -350px;
        width: 350px;
        z-index: 9999;
        visibility: hidden;
        opacity: 0;
        overflow: visible;
        @include transition(all 500ms ease);
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include media-breakpoint-down(sm) {
            width: calc(100% - 30px);
        }
        .inner-body {
            padding: 50px 0;
            .offcanvas-top {
                display: flex;
                justify-content: flex-end;
                padding: 0 25px 30px;
                height: auto;
                transform: none;
                border-bottom: 0;
                .button-close {
                    font-size: 24px;
                    color: #c0c0c0;
                }
            }
            .offcanvas-menu_area {
                .offcanvas-navigation {
                    .mobile-menu {
                        & > li {
                            height: 100%;
                            & > a {
                                span {
                                    position: relative;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                }
                            }
                        }
                        li {
                            position: relative;
                            &:not(:last-child) {
                                padding-bottom: 25px;
                            }
                            & > .menu-expand {
                                position: absolute;
                                right: 0;
                                top: 2px;
                                width: 50px;
                                height: 100%;
                                line-height: 40px;
                                cursor: pointer;
                                text-align: center;
                                margin-right: 4px;
                                transition: $baseTransition;
                                font-size: 20px;
                            }
                            a {
                                color: #c0c0c0;
                                font-size: 14px;
                                text-transform: uppercase;
                                display: block;
                                padding: 0 30px;
                                i {
                                    font-size: 20px;
                                }
                            }
                            .sub-menu {
                                background-color: rgba(255, 255, 255, 0.05);
                                overflow-y: auto;
                                padding: 25px 0;
                                margin: 20px 0 10px;
                                li {
                                    a {
                                        text-transform: uppercase;
                                        font-size: 11px;
                                        color: #c0c0c0;
                                        span {
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                        }
                                    }
                                    &.menu-open {
                                        > a {
                                            color: $primary;
                                            > span {
                                                > i {
                                                    &:before {
                                                        content: '\e682';
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.menu-open {
                                > a {
                                    color: $primary;
                                    > span {
                                        > i {
                                            &:before {
                                                content: '\';
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.open {
        .offcanvas-body {
            visibility: visible;
            right: 0;
            opacity: 1;
            z-index: 999;
        }
    }
}

.offcanvas-minicart_wrapper {
    .offcanvas-body {
        box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
        background-color: $white;
        padding: 55px 60px 0;
        width: 450px;
        left: auto;
        right: -660px;
        @include media-breakpoint-down(sm) {
            padding: 55px 30px 0;
            width: 100%;
        }
        .minicart-content {
            .minicart-heading {
                display: flex;
                justify-content: space-between;
                padding-bottom: 25px;
                .button-close {
                    font-size: 32px;
                    line-height: 1;
                }
            }
            .minicart-list {
                max-height: 350px;
                position: relative;
                overflow: auto;
                li {
                    &:not(:last-child) {
                        border-bottom: 1px solid $border-color;
                        padding-bottom: 30px;
                        margin-bottom: 30px;
                    }
                    &.minicart-product {
                        display: flex;
                        a {
                            &.product-item_remove {
                                i {
                                    position: absolute;
                                    font-size: 18px;
                                    top: auto;
                                    right: 15px;
                                }
                            }
                        }
                        .product-item_img {
                            transition: $baseTransition;
                            flex-basis: 70px;
                            max-width: 70px;
                        }
                        .product-item_content {
                            flex-basis: calc(100% - 70px);
                            max-width: calc(100% - 70px);
                            padding-left: 20px;
                            padding-right: 10px;
                            a {
                                &.product-item_title {
                                    line-height: 1.4;
                                    font-size: 16px;
                                    width: 90%;
                                    display: block;
                                }
                            }
                            .product-item_quantity {
                                display: block;
                                padding-top: 10px;
                                font-size: 13px;
                                line-height: 24.7px;
                            }
                        }
                    }
                }
            }
        }
        .minicart-item_total {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 25px 0 30px;
        }
        .group-btn_wrap {
            padding-bottom: 60px;
        }
    }
    &.open {
        .offcanvas-body {
            left: auto;
            right: 0;
        }
    }
}

/*----------------------------------------*/
/*  15. Newsletter
/*----------------------------------------*/
.newsletter{
    &-item{
        @include media-breakpoint-up(xl){
            width: calc(100% - 65px);
        }
        @include media-breakpoint-up(lg){
            display: flex;
            justify-content: space-between;
            margin-right: auto;
        }
    }
    &-content{
        @include media-breakpoint-down(lg){
            padding-bottom: 10px;
        }
        .title{
            font-size: 60px;
            line-height: 60px;
            @include media-breakpoint-up(lg){
                margin-right: 40px;
                padding-right: 40px;
                position: relative;
                &:before{
                    background-color: #ffb390;
                    width: 1px;
                    height: 100px;
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }
            }
            @include media-breakpoint-down(xl){
                font-size: 50px;
            }
            @include media-breakpoint-down(sm){
                font-size: 40px;
            }
        }
    }
    &-form{
        position: relative;
        width: 625px;
        @include media-breakpoint-down(xl){
            width: 510px;
        }
        @include media-breakpoint-down(lg){
            width: 100%;
        }
        .input-field{
            background-color: transparent;
            border: 1px solid #ffb390;
            width: 100%;
            height: 75px;
            padding-left: 25px;
            padding-right: 210px;
            font-size: 18px;
            @include media-breakpoint-down(sm){
                padding-right: 25px;
            }
            &::placeholder{
                color: #ffb390;
                opacity: 1;
            }
        }
        .form-btn_wrap{
            @include media-breakpoint-up(sm){
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
            }
            .btn{
                font-family: $rajdhani;
                font-weight: 600;
                padding: 0 30px;
                height: 100%;
                @include media-breakpoint-down(sm){
                    margin-top: 15px;
                    width: 100%;
                    height: 70px;
                }
            }
        }
    }
}
/*----------------------------------------*/
/*  16. Footer
/*----------------------------------------*/
.widget{
    &-item{
        .heading{
            font-weight: 600;
        }
        a{
            &:hover{
                color: $white;
            }
        }
        .inquary{
            h5{
                font-size: 18px;
                line-height: 26px;
            }
            a{
                font-size: 20px;
                line-height: 26px;
            }
        }
    }
    &-list-item{
        &.specific-item{
            @include media-breakpoint-up(lg){
                padding-top: 40px;
            }
        }
        li{
            &:not(:last-child){
                padding-bottom: 15px;
            }
            a{
                font-size: 18px;
            }
        }
    }
}

/* ---Fotter Bottom--- */
.footer{
    &-bottom{
        .social-link{
            li{
                border: 1px solid #415981;
                text-align: center;
                padding: 0;
                &:not(:last-child){
                    margin-right: 20px;
                }
                a{
                    display: block;
                    i{
                        transition: $baseTransition;
                        vertical-align: middle;
                        padding: 5px 10px;
                    }
                }
                &:hover{
                    a{
                        i{
                            color: $primary;
                        }
                    }
                }
            }
        }
        .copyright,
        .social-link{
            @include media-breakpoint-down(sm){
                display: flex;
                justify-content: center;
            }
        }
        .copyright{
            text-align: right;
            @include media-breakpoint-down(sm){
                padding-top: 10px;
                text-align: center;
            }
            &-text{
                i{
                    vertical-align: middle;
                }
            }
        }
    }
}
/*----------------------------------------*/
/*  12. Team
/*----------------------------------------*/
.team{
    &-item{
        &:hover{
            .team{
                &-content{
                    bottom: 30px;
                }
                &-img{
                    &:before{
                        visibility: visible;
                        opacity: 0.72;
                    }
                    .add-action{
                        background-color: $primary;
                        li{
                            &.mail-wrap{
                                i{
                                    background-color: $secondary;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &-img{
        position: relative;
        display: flex;
        overflow: hidden;
        z-index: 2;
        &:before{
            background-color: $secondary;
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: $baseTransition;
        }
        .add-action{
            background-color: $secondary;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0;
            width: 75px;
            transition: $baseTransition;
            z-index: 1;
            li{
                text-align: center;
                &.mail-wrap{
                    i{
                        background-color: $primary;
                        display: block;
                        width: 25px;
                        height: 25px;
                        line-height: 25px;
                    }
                }
            }
        }
    }
    &-content{
        transition: $baseTransition;
        position: absolute;
        bottom: -130px;
        left: 30px;
        z-index: 2;
        width: 150px;
        .title{
            color: $white;
        }
        .occupation{
            color: $primary;
            font-size: 18px;
        }
    }
    &-social-link-wrap{
        height: 30px;
        .social-link{
            flex-direction: column;
            width: 100%;
            opacity: 0;
            visibility: hidden;
            padding-top: 25px;
            li{
                transition: $baseTransition;
                position: relative;
                right: -45px;
                display: block;
                &:not(:last-child){
                    padding-bottom: 15px;
                    padding-right: 0;
                }
                &:nth-child(1) {
                    transition-delay: 100ms;
                }
                &:nth-child(2) {
                    transition-delay: 150ms;
                }
                &:nth-child(3) {
                    transition-delay: 200ms;
                }
                a{
                    background-color: $secondary;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    display: block;
                    text-align: center;
                }
            }
        }
        &:hover{
            .social-link{
                visibility: visible;
                opacity: 1;
                li{
                    right: 0;
                }
            }
        }
    }
}
/*----------------------------------------*/
/*  22. Cart
/*----------------------------------------*/
.table-content {
	table {
        &.table > :not(:last-child) > :last-child > *{
            border-bottom-color: $border-color;
        }
		td {
			&.product-subtotal {
				font-size: 16px;
				font-weight: 700;
			}
			&.quantity {
				.cart-plus-minus {
					margin: 0 auto;
				}
			}
		}
	}
}

.cart-area {
	.table-content {
		table {
			td {
				&:nth-child(3) {}
			}
		}
	}
}

.coupon-all {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
	.coupon {
		float: left;
		input {
			background-color: transparent;
			border: 1px solid $border-color;
			font-size: 13px;
			border-radius: 0;
			height: 42px;
			width: 120px;
			padding: 0 10px;
		}
	}
}

.cart-page-total {
	padding-top: 30px;
	> h2 {
		font-size: 25px;
		font-weight: 700;
		margin-bottom: 20px;
		text-transform: capitalize;
	}
	> ul {
		border: 1px solid $border-color;
		li {
			list-style: none;
			font-size: 15px;
			padding: 10px 30px;
			border-bottom: 1px solid $border-color;
			font-weight: 700;
			&:last-child {
				border-bottom: 0;
			}
			span {
				float: right;
			}
		}
	}
	a {
		background-color: $secondary;
		border: 1px solid $border-color;
		color: $white;
		display: inline-block;
		margin-top: 30px;
		padding: 10px 20px;
		text-transform: capitalize;
		transition: $baseTransition;
		&:hover{
			background-color: $primary;
			color: $white;
		}
	}
}

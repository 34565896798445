/*----------------------------------------*/
/*  08. About
/*----------------------------------------*/
.about {
    &-img-wrap {
        position: relative;
        .group-img {
            @media (max-width: 479px) {
                padding-left: 0 !important;
            }
        }
        &.style {
            &-2 {
                display: flex;
                @media (max-width: 479px) {
                    flex-direction: column;
                }
            }
        }
        .experience {
            background-image: url('../images/about/experience-user-icon.png');
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: contain;
            background-color: $secondary;
            position: absolute;
            width: 415px;
            bottom: -50px;
            right: -65px;
            display: flex;
            padding: 30px;
            align-items: center;
            border-bottom: 5px solid $primary;
            @include media-breakpoint-down(xl) {
                right: 35px;
            }
            @include media-breakpoint-down(sm) {
                right: 35px;
                width: calc(100% - 35px);
            }
            .year {
                font-family: $rajdhani;
                font-weight: 700;
                font-size: 72px;
                line-height: 30.03px;
                display: block;
                @include media-breakpoint-down(sm) {
                    font-size: 40px;
                }
            }
            h5 {
                padding-left: 15px;
                font-weight: 500;
                font-size: 25px;
                line-height: 36px;
                span {
                    font-weight: 700;
                    display: block;
                    font-size: 40px;
                    @include media-breakpoint-down(sm) {
                        font-size: 35px;
                    }
                }
            }
        }
    }
    &-pattern {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    &-img {
        padding-top: 30px;
        padding-left: 70px;
        margin-bottom: 60px;
    }
    &-content {
        @include media-breakpoint-up(xl) {
            padding-left: 90px;
        }
        @include media-breakpoint-down(lg) {
            padding-top: 25px;
        }
        .experience {
            &.style {
                &-2 {
                    display: flex;
                    justify-content: space-between;
                    max-width: 370px;
                    align-items: flex-end;
                    margin-bottom: 35px;
                    .experience {
                        &-content {
                            display: flex;
                            width: calc(100% - 80px);
                        }
                        &-img {
                            width: 80px;
                        }
                    }
                    .year {
                        font-family: $rajdhani;
                        font-weight: 700;
                        font-size: 72px;
                        align-self: flex-end;
                        line-height: 43px;
                        @media (max-width: 479px) {
                            font-size: 35px;
                        }
                    }
                    .our-progress {
                        font-weight: 500;
                        margin-left: 20px;
                        margin-bottom: 0;
                        font-size: 25px;
                        @media (max-width: 479px) {
                            font-size: 18px;
                        }
                        span {
                            font-weight: 700;
                            display: block;
                            font-size: 40px;
                            @media (max-width: 479px) {
                                font-size: 30px;
                            }
                        }
                    }
                }
            }
        }
        .establish {
            text-transform: uppercase;
            color: $primary;
            font-weight: 700;
            display: block;
            font-size: 20px;
        }
        .title {
            font-size: 60px;
            line-height: 60px;
            @media (max-width: 479px) {
                font-size: 40px;
                line-height: 40px;
            }
        }
        .sub-title {
            max-width: 510px;
            font-weight: 700;
            @media (max-width: 479px) {
                line-height: 1.3;
            }
        }
        .list {
            &-item {
                li {
                    display: flex;
                    &:not(:last-child) {
                        padding-bottom: 25px;
                    }
                    .list-text {
                        span {
                            font-size: 18px;
                        }
                    }
                }
                &.style {
                    &-2 {
                        li {
                            align-items: center;
                            padding-bottom: 0;
                            @media (max-width: 479px) {
                                &:not(:last-child) {
                                    padding-bottom: 15px !important;
                                }
                            }
                            .list {
                                &-icon {
                                    i {
                                        font-size: 28px;
                                        @include media-breakpoint-down(xl) {
                                            font-size: 22px;
                                        }
                                    }
                                }
                                &-text {
                                    span {
                                        font-size: 28px;
                                        @include media-breakpoint-down(xl) {
                                            font-size: 22px;
                                        }
                                    }
                                }
                            }
                            .sub {
                                &-list {
                                    &-holder {
                                        width: 50%;
                                    }
                                    &-item {
                                        display: flex;
                                        flex-wrap: wrap;
                                        width: 100%;
                                        li {
                                            width: 50%;
                                            @media (max-width: 479px) {
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-icon {
                padding-right: 15px;
                i {
                    color: $primary;
                    vertical-align: middle;
                }
            }
        }
        &.style {
            &-2 {
                @include media-breakpoint-down(xl) {
                    padding-left: 30px;
                }
                @media (max-width: 479px) {
                    padding-left: 0;
                }
            }
        }
    }
    &-style {
        &-2 {
            .about {
                &-img {
                    margin-bottom: 0;
                }
                &-content {
                    padding-left: 65px;
                    padding-top: 35px;
                    @media (max-width: 479px) {
                        padding-left: 0;
                    }
                }
            }
        }
    }
}

/*----------------------------------------*/
/*  20. Contact
/*----------------------------------------*/
.contact{
    &-img-wrap{
        position: relative;
    }
    &-img{
        padding-bottom: 30px;
        padding-left: 70px;
    }
    &-pattern{
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
    &-content{
        .sub-title{
            font-size: 28px;
            color: $primary;
            display: block;
        }
        .title{
            font-size: 60px;
            line-height: 60px;
        }
    }
    &-info{
        .title{
            color: $primary;
            font-size: 28px;
        }
        .short-desc{
            max-width: 260px;
            &.with-border{
                border-bottom: 1px solid $border-color;
                padding-bottom: 30px;
            }
        }
        ul{
            li{
                &:not(:last-child){
                    padding-bottom: 5px;
                }
            }
        }
    }
}

/* ---Contact Form--- */
.contact{
    &-form{
        .group-input{
            display: flex;
            @include media-breakpoint-down(sm){
                flex-direction: column;
            }
        }
        .input-field,
        .textarea-field{
            border: 1px solid $border-color;
            padding: 15px;
            width: 100%;
            height: 45px;
        }
        .textarea-field{
            height: 280px;
        }
        &-desc{
            max-width: 370px;
        }
        .form-messege{
            &.success{
                color: green;
            }
            &.error{
                color: red;
            }
        }
    }

}

.map-with-pattern{
    position: relative;
    .map-size{
        width: 480px;
        height: 530px;
        padding-bottom: 70px;
        @include media-breakpoint-down(xl){
            width: 100%;
        }
    }
    .contact-pattern{
        position: absolute;
        bottom: 0;
        left: auto;
        right: 0;
        z-index: -1;
    }
}